<template>
  <div>
    <b-card>
      <b-card-title class="mb-1">ข้อมูลรวมทุกเดือน</b-card-title>
      <hr class="mt-50" />
      <h5>
        ผู้ใช้ <span v-if="mainData.customer_information">{{ mainData.customer_information.email }}</span>
      </h5>
      <p>
        ชื่อ-นามสกุล <span v-if="mainData.customer_information">{{ mainData.customer_information.full_name }}</span>
      </p>
      <table class="w-100">
        <tr>
          <th class="pb-50 w-75">
            <b-badge class="mr-75" variant="warning">
              <feather-icon icon="CreditCardIcon" />
            </b-badge>
            <span class="font-weight-bold">เครดิตคงเหลือ</span>
          </th>

          <td class="pb-50 text-right">
            <b-skeleton v-if="pageLoading || mainLoading" width="20%" class="m-0 ml-auto" />
            <span v-else> {{ gFormatNumberToCurrency(mainData.credit_balance || 0) }} บาท </span>
          </td>
        </tr>
        <tr>
          <th class="pb-50 w-75">
            <b-badge class="mr-75" variant="success">
              <feather-icon icon="TrendingUpIcon" />
            </b-badge>
            <span class="font-weight-bold">ยอดเติมเครดิต</span>
          </th>

          <td class="pb-50 text-right">
            <b-skeleton v-if="pageLoading || mainLoading" width="20%" class="m-0 ml-auto" />
            <span v-else class="text-success"> {{ gFormatNumberToCurrency(mainData.topup_amount || 0) }} บาท </span>
          </td>
        </tr>
        <tr>
          <th class="pb-50 w-75">
            <b-badge class="mr-75" variant="danger">
              <feather-icon icon="TrendingDownIcon" />
            </b-badge>
            <span class="font-weight-bold">ยอดคืนเครดิต</span>
          </th>

          <td class="pb-50 text-right">
            <b-skeleton v-if="pageLoading || mainLoading" width="20%" class="m-0 ml-auto" />
            <span v-else class="text-danger"> {{ gFormatNumberToCurrency(mainData.refund_amount || 0) }} บาท </span>
          </td>
        </tr>
      </table>

      <div class="d-flex">
        <b-input type="text" disabled class="w-25 mr-50" :value="userId" />
        <button-widget text="copy payment link" variant="primary" @click="handleCopyPaymentLink" />
      </div>

      <button-widget
        text="จัดการเครดิตผู้ใช้"
        variant="primary"
        block
        class="mt-1"
        :disabled="pageLoading || mainLoading"
        @click="openModalUserCreditSetting"
      />
    </b-card>
    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h3 class="mb-2 mb-md-0">สรุปข้อมูลประจำเดือน</h3>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="cDate"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="pageLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row cols="1" cols-md="2">
      <b-col>
        <statistic-card-horizontal
          icon="ImageIcon"
          :statistic="gFormatNumberToCurrency(subData.topup_amount_total || 0)"
          statistic-title="ยอดซื้อรูป"
          :loading="pageLoading || subLoading"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="ImageIcon"
          :statistic="gFormatNumberToCurrency(subData.count_buy_image || 0)"
          statistic-title="จำนวนรูปที่ซื้อ"
          :loading="pageLoading || subLoading"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="TrendingUpIcon"
          :statistic="gFormatNumberToCurrency(subData.topup_amount || 0)"
          statistic-title="ยอดเติมเครดิต"
          :loading="pageLoading || subLoading"
          color="success"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="TrendingDownIcon"
          :statistic="gFormatNumberToCurrency(subData.refund_total || 0)"
          statistic-title="ยอดคืนเครดิต"
          :loading="pageLoading || subLoading"
          color="danger"
        />
      </b-col>
      <b-col md="12">
        <statistic-card-horizontal
          icon="TrendingDownIcon"
          :statistic="gFormatNumberToCurrency(subData.fail_topup_amount || 0)"
          statistic-title="ยอดเติมเครดิตไม่สำเร็จ"
          :loading="pageLoading || subLoading"
          color="warning"
        />
      </b-col>
    </b-row>
    <b-modal
      ref="refModalUserCreditSetting"
      title="จัดการเครดิตผู้ใช้"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="callApiToManagementCreditUser"
      @hide="formUserCredit = {}"
    >
      <b-form-group label="เครดิตคงเหลือ" label-for="f_credit_user">
        <b-form-input
          v-model="formUserCredit.credit_balance"
          name="f_credit_user"
          type="number"
          number
          :disabled="true"
        />
      </b-form-group>
      <b-form-group label="เพิ่มหรือลดเครดิต" label-for="f__management_credit_user">
        <b-form-input
          v-model="formUserCredit.managementTotal"
          name="f__management_credit_user"
          type="number"
          number
          :disabled="false"
        />
      </b-form-group>
      <!-- <b-form-group label="เครดิตรวม" label-for="f_total_credit_user">
        <b-form-input v-model="formUserCredit.total" name="f_total_credit_user" type="number" number :disabled="true" />
      </b-form-group> -->
      <!-- <pre>{{ formUserCredit }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    vSelect,
    StatisticCardHorizontal,
  },
  props: {
    keyTab: {
      type: String,
      default: '',
    },
    dateSelected: {
      type: String,
      default: null,
    },
    dateSelectOptions: {
      type: Array,
      default: () => [],
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formUserCredit: {},
      mainData: {},
      mainLoading: false,
      subData: {},
      subLoading: false,
    }
  },
  computed: {
    cDate: {
      get() {
        return this.dateSelected
      },
      set(val) {
        this.$emit('changeDateSelect', val)
      },
    },
  },
  watch: {
    dateSelected(newVal) {
      // console.log('dateSelected', newVal)
      this.getSubDataByUserIdAndDate(this.userId, newVal)
    },
  },
  async created() {
    // console.log('this.dateSelected', this.dateSelected)
    this.getMainDataByUserId(this.userId)
    this.getSubDataByUserIdAndDate(this.userId, this.dateSelected)
  },
  methods: {
    resetFormUserCredit() {
      this.formUserCredit = {}
    },
    openModalUserCreditSetting() {
      this.formUserCredit = { ...this.mainData }
      this.$refs.refModalUserCreditSetting.show()
    },
    async callApiToManagementCreditUser() {
      if (!this.formUserCredit?.customer_information?.id || !this.formUserCredit.managementTotal) {
        this.gDisplayToast('ยอดเครดิตที่เพิ่มหรือลดต้องไม่เป็นค่าว่าง', 'โปรดระบุยอดเครดิตที่จะเพิ่มหรือลด', 'danger')
        return
      }
      this.gOpenPageLoading()
      // /api/admin/client-update-credit
      const resp = await this.api
        .postV2(
          '/api/admin/client-update-credit',
          { user_id: this.formUserCredit.customer_information.id, amount: this.formUserCredit.managementTotal },
          this,
        )
        .catch(() => null)
      // console.log('callApiToManagementCreditUser', resp)
      if (resp?.code === 200) {
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.getMainDataByUserId(this.userId)
        this.getSubDataByUserIdAndDate(this.userId, this.dateSelected)
      } else {
        this.gDisplayToast('ดำเนินการไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
      this.gClosePageLoading()
      this.resetFormUserCredit()
    },
    async getMainDataByUserId(userId) {
      if (!userId) return
      this.mainLoading = true
      const resp = await this.api.get(`api/customers/dashboard/customer-information/${userId}`).catch(() => null)
      // console.log('getMainDataByUserId', resp)
      if (resp) {
        this.mainData = { ...resp }
      } else {
        this.mainData = {}
      }
      this.mainLoading = false
    },
    async getSubDataByUserIdAndDate(userId, date) {
      if (!userId || !date) return
      this.subLoading = true

      const resp = await this.api.get(`/api/customers/dashboard/monthly-summary/${userId}/${date}`).catch(() => null)
      // console.log('getSubDataByUserIdAndDate', resp)
      if (resp) {
        this.subData = { ...resp }
      } else {
        this.subData = {}
      }

      this.subLoading = false
    },
    async handleCopyPaymentLink() {
      const url = `https://payment.ongphra.com?id=${this.userId}`
      const resp = await this.$copyText(url).catch(() => {})
      if (resp.text !== url) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }
      this.gDisplayToast('คัดลอกแล้ว')
    },
  },
}
</script>

<style lang="scss" scoped></style>
